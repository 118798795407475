import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */ '../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home');
const About = () => import(/* webpackChunkName: "about" */ '../views/About');
const OurHistory = () => import(/* webpackChunkName: "history" */ '../views/OurHistory');
const Donate = () => import(/* webpackChunkName: "history" */ '../views/Donate');
const FounderDetails = () => import(/* webpackChunkName: "history" */ '../views/FounderDetailsPage');
const BoardMemberDetails = () => import(/* webpackChunkName: "history" */ '../views/BoardMemberDetailsPage');
const Contact = () => import(/* webpackChunkName: "history" */ '../views/ContactUs');
const Volunteer = () => import(/* webpackChunkName: "history" */ '../views/VolunteerWithUs');
const WorkWithUs = () => import(/* webpackChunkName: "history" */ '../views/WorkWithUs');
const WorkWithUsShowcase = () => import(/* webpackChunkName: "history" */ '../views/WorkWithUsShowcase');
const PartnerInProject = () => import(/* webpackChunkName: "history" */ '../views/PartnerInProject');
const PartnerWithUs = () => import(/* webpackChunkName: "history" */ '../views/WorkWithUsShowcase');
const Ourfocus = () => import(/* webpackChunkName: "history" */ '../views/components/OurFocus');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    mode: 'history',
    base: process.env.BASE_URL,
    children: [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about/',
        name: 'About',
        component: About
    },
    {
        path: '/our-history/',
        name: 'History',
        component: OurHistory
    },
    {
        path: '/founder-info/:name',
        props: true,
        name: 'FounderDetails',
        component: FounderDetails
    },
    {
        path: '/board-member/:name',
        props: true,
        name: 'Board Member Details',
        component: BoardMemberDetails
    },
    {
        path: '/donate/',
        name: 'Donate',
        component: Donate
    },
    {
        path: '/contact/',
        name: 'contact-us',
        component: Contact
    },
    {
        path: '/volunteer/',
        name: 'volunteer-with-us',
        component: Volunteer
    },
    {
        path: '/work-with-us/',
        name: 'work-with-us',
        component: WorkWithUs
    },
    {
        path: '/work-with-us-showcase/',
        name: 'work-with-us-showcase',
        component: WorkWithUsShowcase
    },
    {
        path: '/partner-in-project/',
        name: 'partner-in-project',
        component: PartnerInProject
    },
    {
        path: '/partner-with-us/',
        name: 'partner-with-us',
        component: PartnerWithUs
    },
    {
        path: '/programs/:id/',
        name: 'Ourfocus',
        component: Ourfocus
    }

    ]
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     } else {
    //         return { x: 0, y: 0 };
    //     }
    // }

};
