const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/';
} else {
    baseUrl = '/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        status: baseUrl + 'auth/status/'
    },
    admin: {
        ContactUs: {
            // email: baseUrl + 'website/enquiry/email/send/'
            email: 'https://esafglobalcanada.xeoscript.com/website/enquiry/email/send/',
            add: baseUrl + 'esaf-api/contact-us/'
        },
        ourWork: {
            list: baseUrl + 'esaf-api/our-works/list/'
        },
        impactStory: {
            list: baseUrl + 'esaf-api/impact-stories/website-list/'
        },
        workWithUs: {
            add: baseUrl + 'esaf-api/work-with-us/add-edit/'
        },
        volunteerWithUs: {
            add: baseUrl + 'esaf-api/volunteer/add-edit/'
        },
        partnerWithUs: {
            add: baseUrl + 'esaf-api/partner-with-us/add-edit/'
        },
        project: {
            options: baseUrl + 'esaf-api/project/options/'
        },
        vacancy: {
            options: baseUrl + 'esaf-api/vacancy/options/'
        },
        program: {
            list: baseUrl + 'esaf-api/program/list/',
            details: baseUrl + 'esaf-api/program/details/'
        }
    }
};
