import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';

Vue.use(VueRouter);

const routes = [
    appRoutes
];

const scrollBehavior = function (to, from, savedPosition) {
    if (savedPosition) {
    // savedPosition is only available for popstate navigations.
        return savedPosition;
    } else {
        const position = { x: 0, y: 0 };

        // scroll to anchor by returning the selector
        if (to.hash) {
            position.selector = to.hash;

            // specify offset of the element
            // if (to.hash === '#anchor2') {
            //     position.offset = { y: 100 };
            // }

            // bypass #1number check
            if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
                return position;
            }

            // if the returned position is falsy or an empty object,
            // will retain current scroll position.
            return false;
        }

        return new Promise(resolve => {
            // check if any matched route config has meta that requires scrolling to top
            if (to.matched.some(m => m.meta.scrollToTop)) {
                // coords will be used if no selector is provided,
                // or if the selector didn't match any element.
                position.x = 0;
                position.y = 0;
            }

            // wait for the out transition to complete (if necessary)
            this.app.$root.$once('triggerScroll', () => {
                // if the resolved position is falsy or an empty object,
                // will retain current scroll position.
                resolve(position);
            });
        });
    }
};

if (process.env.NODE_ENV !== 'production') {
    const docsRoutes = require('lego-framework/src/router/docs-layout');
    routes.push(docsRoutes.default);
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     } else {
    //         return {
    //             x: 0,
    //             y: 0
    //         };
    //     }
    // }
    // scrollBehavior (to, from, savedPosition) {
    //     if (from.path !== to.path) {
    //         const position = {};
    //
    //         if (to.hash) {
    //             position.selector = to.hash;
    //             console.log('element', document.querySelector(to.hash));
    //             // alert(to.hash);
    //             if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
    //                 return position;
    //             }
    //             return { selector: to.hash };
    //         } else {
    //             return { x: 0, y: 0 };
    //         }
    //     }
    // }
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
